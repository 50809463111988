<!--分页-->
<template>
  <el-pagination
    :page-sizes="[10, 20, 40, 80, 100]"
    :current-page.sync="page"
    :page-size.sync="pageSize"
    background
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    @size-change="changePageSize"
    @current-change="changePage"
  >
  </el-pagination>
</template>

<script>
export default {
  name: "GPagination",
  props: {
    total: {
      type: Number,
      default: 0,
    },
    pageNum: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    page: {
      get() {
        return this.pageNum;
      },
      set(value) {
        this.$emit("update:pageNum", value);
      },
    },
    pageSize: {
      get() {
        return this.size;
      },
      set(value) {
        this.$emit("update:size", value);
      },
    },
  },
  methods: {
    // 修改页码数量
    changePageSize(size) {
        this.$emit("change", { pageNum: this.page, pageSize: size });
    },
    // 修改页码
    changePage(page) {
      this.$emit("change", { pageNum: page, pageSize: this.size });
    },
  },
};
</script>

<style lang="scss">
</style>
